import React from 'react';
import { AltLayout } from '../layout/AltLayout';
import { graphql } from 'gatsby';
import { Hero } from '../components/Hero';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';
import { colors, device } from '../components/layout/GlobalStyles';
import { RelatedServices } from '../components/Related-Services';
import { FormPanels } from '../components/Form-Panels';
import { BluePanel } from '../components/Blue-Panel';
import { InsurancePlansPanels } from '../components/Insurance-Plans-Panels';
import { Seo } from '../components/Seo';
import { AboutCarousel } from '../components/About-Carousel';

const PageContainer = styled.article`
    padding: 0px 0px 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .doctor-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .doctor-image {
        border-radius: 100px 20px;
        img {
            height: 322px;
            width: 322px;
            border-radius: 100px 20px;
        }
    }
    /* images */
    /* .doctor-image {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
            border-radius: 100px 20px;
        }
        border: none;
        border-radius: 100px 20px;
    }
    .second-panel {
        background: teal;
    }
    @media ${device.tablet} {
        padding: 0px 0px;
        max-width: 580px;

        h2 {
            margin: 54px 0px 15px;
        }

        /* .doctor-image {
            width: 400px;
        } */
    @media ${device.tablet} {
        .doctor-image {
            img {
                height: 522px;
                width: 522px;
            }
        }
    }
    @media ${device.laptopS} {
        .doctor-image {
            img {
                height: 522px;
                width: 522px;
            }
        }
        > div {
            margin: 0em 0em 50px;
        }
        padding: 108px 0px 70px;
    }
    @media ${device.laptopL} {
    }
    @media ${device.desktop} {
    }
`;

const StyledMDXBody = styled.div`
    padding: 108px 20px 0px;
    margin-top: 00px;
    /* main service pictures */
    ul + div {
        border-radius: 20px;
        img {
            border-radius: 20px;
        }
    }
    p + div {
        margin: 1em 0em;
        border-radius: 20px;
        img {
            border-radius: 20px;
            margin-bottom: 30px;
        }
    }
    p {
        font-family: 'lato';
    }
    h2 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: ${colors.blueMedium};
    }
    a {
        color: #518ee9;
        font-weight: 600;
        transition: 0.2s ease-in-out;
        &:hover {
            transition: 0.2s ease-in-out;
            color: #144bbf;
            font-weight: 600;
        }
    }
    ul {
        margin: 30px 0px;
        padding-left: 1.5em;
        li {
            font-size: 18px;
            margin: 0.5em 0em;
        }
    }
    @media ${device.tablet} {
        max-width: 650px;
        padding: 108px 20px 0px;
        margin: 0 auto;
        h2 {
            margin-top: 2em;
        }
        /* main service pictures */
        p {
        }
        p + div {
            margin: 1em 0em;
            border-radius: 20px;
            img {
                width: 750px;
                border-radius: 20px;
            }
        }
    }
    @media ${device.laptopS} {
        max-width: 750px;
        margin: 0 auto;
        padding-top: 32px;
        /* main service pictures */
        h2 {
            margin-top: 60px;
        }
        p {
            width: 750px;
        }
        p + div {
            margin: 60px 0em 20px;
            img {
                width: 750px;
                border-radius: 20px;
            }
        }
    }
    @media ${device.laptop} {
        max-width: 846px;
    }
    @media ${device.laptopL} {
        max-width: 1046px;
        /* main service pictures */
        p {
            width: 1046px;
        }
        p + div {
            margin: 30px 0em 0px;
            img {
                width: 1046px;
                height: 450px;
                border-radius: 20px;
            }
        }
    }
`;

const Service = ({
    data: {
        allMarkdown: { frontmatter, body },
        relatedServices,
    },
}) => {
    return (
        <>
            <Seo title={frontmatter.meta.title} description={frontmatter.meta.description} />
            <PageContainer>
                {frontmatter.title === 'Dr. Andrew Dethloff DDS, MA' ? (
                    <Hero
                        breadCrumbsUrl={frontmatter.breadCrumbsUrl}
                        title={frontmatter.title}
                        breadCrumbs={frontmatter.breadCrumbs}
                        image={frontmatter.images[1]}
                    />
                ) : (
                    <Hero
                        breadCrumbsUrl={frontmatter.breadCrumbsUrl}
                        title={frontmatter.title}
                        breadCrumbs={frontmatter.breadCrumbs}
                        image={frontmatter.images[0]}
                    />
                )}
                <StyledMDXBody>
                    Posted on: {frontmatter.date}
                    <MDXRenderer images={frontmatter.images}>{body}</MDXRenderer>
                </StyledMDXBody>
                {frontmatter.slug === '/about-us/' && <AboutCarousel />}
                {frontmatter.slug === '/about-us/new-patient-forms/' && <FormPanels />}
                {frontmatter.slug === '/about-us/insurance/' && <InsurancePlansPanels />}
                <BluePanel />
                {!frontmatter.slug.includes('blog') && (
                    <RelatedServices relatedServices={relatedServices} />
                )}
            </PageContainer>
        </>
    );
};

export const query = graphql`
    query ($id: String!, $category: String!) {
        allMarkdown: mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                category
                title
                breadCrumbs
                breadCrumbsUrl
                slug
                date(formatString: "MMM DD, YYYY")
                images {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100)
                    }
                }
            }
            body
        }
        relatedServices: allMdx(filter: { frontmatter: { category: { eq: $category } } }) {
            edges {
                node {
                    id
                    frontmatter {
                        slug
                        images {
                            childrenImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                        }
                        category
                        breadCrumbs
                        icon {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                        }
                    }
                    body
                }
            }
        }
    }
`;

Service.Layout = AltLayout;

export default Service;
