import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImageProps, GatsbyImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { Link } from 'gatsby';

const StyledRelatedServices = styled.div`
    h3 {
        text-align: center;
        color: ${colors.blueMedium};
        font-weight: 600;
        font-size: 40px;
        margin-top: 44px;
        line-height: 132.5%;

        font-family: 'Noto Serif';
        font-style: normal;
    }
    @media ${device.laptop} {
        h3 {
            margin-top: 84px;
        }
    }
`;

const Panels = styled.ul`
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 64px 0px 128px;
    align-self: center;
    padding-left: 0em;
    @media ${device.tablet} {
        flex-wrap: wrap;
        overflow-x: hidden;
        margin: 45px auto 125px;
    }
    @media ${device.laptopS} {
        display: flex;
        align-items: center;
        overflow-x: hidden;
        flex-wrap: wrap;

        justify-content: center;
        margin: 34px 0px 128px;
    }
    @media ${device.laptop} {
        display: flex;
        align-items: center;
        overflow-x: hidden;
        width: 1075px;
        flex-wrap: wrap;
        justify-content: center;
        margin: 34px 0px 128px;
    }
`;

const Panel = styled.li`
    position: relative;
    border-radius: 15px;
    min-width: 305px;
    height: 305px;
    z-index: 0;
    margin: 0px 10px 0px 20px;
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    border: 2px solid black;
    color: ${colors.offWhite};
    transition: 0.2 ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    min-width: 305px;
    img {
        border-radius: 0px 0px 15px 15px;
        padding: 0em;
        transform: scale(1.05);
        transition: 0.1s ease-in;
    }
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 24px, rgba(0, 0, 0, 0.12) 0px 7px 7px;
    }
    &:hover {
        transition: 0.2 ease-in-out;
        border: 2px solid #9af9ff;
    }
    a {
        transition: 0.2 ease-in-out;
        color: ${colors.offWhite};
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
            color: #9af9ff;
        }
    }
    .icon {
        img {
            width: 96px;
            margin: 0 auto;
            height: 96px;
        }
    }
    p {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        z-index: 20;
        line-height: 152.5%;
        font-family: 'lato';
    }
    li {
        display: flex;
    }
    .background-image {
        position: absolute;
        left: 0em;
        z-index: -1;
        img {
            filter: brightness(50%);
            width: 100%;
            z-index: -2;
            height: 100%;
            border-radius: 15px;
        }
        right: 0;
        top: 0em;
        bottom: 0em;
    }
    @media ${device.tablet} {
        margin: 18px;
    }
    @media ${device.laptopS} {
        height: 305px;

        margin: 20px;
    }
`;

interface Props {
    relatedServices: {
        edges: {
            node: {
                frontmatter: {
                    title: React.Key | null | undefined;
                    breadCrumbs: React.Key | null | undefined;
                    icon: GatsbyImageProps;
                    slug: string;
                };
                name:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
            };
        }[];
    };
}

export const RelatedServices: React.FC<Props> = ({ relatedServices }) => {
    return (
        <StyledRelatedServices>
            <div className="container">
                <h3>Related Services</h3>
                <Panels>
                    {relatedServices.edges.map(
                        (service: {
                            node: {
                                frontmatter: {
                                    title: React.Key | null | undefined;
                                    breadCrumbs: React.Key | null | undefined;
                                    icon: GatsbyImageProps;
                                    slug: string;
                                };
                                name:
                                    | boolean
                                    | React.ReactChild
                                    | React.ReactFragment
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                            };
                        }) => (
                            <Panel key={service.node.frontmatter.title}>
                                <Link to={service.node.frontmatter.slug}>
                                    {service.node.frontmatter.icon.childImageSharp && (
                                        <GatsbyImage
                                            className="icon"
                                            alt={service.node.frontmatter.icon.name}
                                            image={
                                                service.node.frontmatter.icon.childImageSharp
                                                    .gatsbyImageData
                                            }
                                        />
                                    )}
                                    {service.node.frontmatter.images[0].childrenImageSharp[0] && (
                                        <GatsbyImage
                                            className="background-image"
                                            alt="test"
                                            image={
                                                service.node.frontmatter.images[0]
                                                    .childrenImageSharp[0].gatsbyImageData
                                            }
                                        />
                                    )}

                                    <p>
                                        {
                                            service.node.frontmatter.breadCrumbs[
                                                service.node.frontmatter.breadCrumbs.length - 1
                                            ]
                                        }
                                    </p>
                                </Link>
                            </Panel>
                        )
                    )}
                </Panels>
            </div>
        </StyledRelatedServices>
    );
};
