import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';

const StyledCarousel = styled.div`
    z-index: 20000;
    max-width: 1100px;

    .blue-border {
        border: 2px solid ${colors.blueMedium};
    }
    iframe {
        border-radius: 15px;
    }
    button {
        background: none;
        border: none;
        display: none;
    }

    .selection-display {
        .arrow {
            display: none;
            cursor: pointer;
        }
    }
    .image-container {
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 1em;
        width: 82vw;
        img,
        iframe {
            height: 400px;
        }
    }
    .pictures-container {
        overflow-x: scroll;
        .scroll {
            width: 2400px;
            display: flex;
            padding: 2em 0em;
            overflow-y: hidden;
        }
        img,
        iframe {
            padding: 0.5em;
            border-radius: 20px;
            width: 205px;
            height: auto;
        }
    }

    @media ${device.tablet} {
        .image-container {
            width: 500px;
        }
        button {
            display: block;
        }
        .selection-display {
            padding: 0em 2em;
            display: flex;
            align-items: center;
            .arrow {
                display: block;
            }
        }
        .pictures-container {
            .scroll {
                width: 3600px;
                height: 280px;
            }
            img,
            iframe {
                padding: 0.5em;
                border-radius: 20px;
                width: 305px;
                height: 180px;
            }
        }
    }
    @media ${device.laptopL} {
        .image-container {
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 0.5em;
            width: 900px;
            img,
            iframe {
                width: 800px;
                height: 500px;
            }
        }
        .pictures-container {
            .scroll {
                padding-left: 2em;
                width: 2400px;
                height: 280px;
            }
        }
    }

    @media ${device.desktop} {
        .pictures-container {
            .scroll {
                width: 2400px;
                height: 280px;
            }
        }
    }
`;

export const Carousel: React.FC = ({ children }) => {
    return <StyledCarousel>{children}</StyledCarousel>;
};
