import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';

const StyledInsurancePlansPanels = styled.div`
    .no-insurance {
        margin: 35px 0px;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 920px;
        margin: auto;
        margin-top: 30px;
        div {
            img {
                width: 300px;
            }
        }
    }
    @media ${device.tablet} {
        margin-bottom: 85px;
        .no-insurance {
            margin: 0px 0px;
        }
        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            max-width: 620px;
            div img {
                width: 250px;
            }
        }
    }
    @media ${device.laptop} {
        .no {
            justify-self: center;
            margin: 0px auto;
            display: flex;
            width: 100%;
            width: 430px;
            height: 257px;
            justify-content: center;
        }
        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            max-width: 920px;
            margin: 30px auto;
            div {
                margin: 0 20px;
                img {
                    width: 300px;
                }
            }
        }
    }
    @media ${device.laptopL} {
        > div {
            margin: 50px auto;
        }
    }
`;

export const InsurancePlansPanels = () => {
    return (
        <StyledInsurancePlansPanels className="container">
            <div>
                <StaticImage
                    src="../images/insurance/delta-dental.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="delta-dental insurance logo"
                />
                <StaticImage
                    src="../images/insurance/cigna.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="cigna insurance logo"
                />
            </div>
            <div>
                <StaticImage
                    src="../images/insurance/metlife.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="metlife insurance logo"
                />
                <StaticImage
                    src="../images/insurance/tricare.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="tricare"
                />
            </div>
            <div>
                <StaticImage
                    src="../images/insurance/dentaquest.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="dentaquest insurance logo"
                />
                <StaticImage
                    src="../images/insurance/united-concordia.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="united-concordia insurance logo"
                />
            </div>
            <div>
                <StaticImage
                    src="../images/insurance/united-healthcare.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="united-healthcare insurance logo"
                />
                <StaticImage
                    src="../images/insurance/careington.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="careington insurance logo"
                />
            </div>
            <div>
                <StaticImage
                    src="../images/insurance/humana.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="humana insurance logo"
                />
                <StaticImage
                    src="../images/insurance/aetna.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="aetna insurance logo"
                />
            </div>
            <div>
                <StaticImage
                    src="../images/insurance/blue-cross-blue-shield.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="blue cross blue shield insurance logo"
                />
                <StaticImage
                    src="../images/insurance/care-credit.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="care credit insurance logo"
                />
            </div>

            <a
                rel="noreferrer"
                href="https://woodmoor-family-dentistry.illumitrac.com/"
                target="__blank"
                className="no"
            >
                <StaticImage
                    className="no-insurance"
                    src="../images/insurance/no-insurance.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="careington insurance logo"
                />
            </a>
        </StyledInsurancePlansPanels>
    );
};
