import * as React from 'react';
import { useState } from 'react';
import { Carousel } from './Carousel';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import arrow from '../images/svgs/left-dark-arrow.svg';

const StyledTour = styled.div`
    button {
        background: none;
        border: none;
    }
    margin: 0 auto;
    width: 80vw;
    .buttons-container {
        width: 300px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        .buttons-container {
            width: 600px;
            margin-top: 240px;
        }
    }
    @media ${device.laptop} {
        width: 980px;
        .buttons-container {
            width: 980px;
            display: flex;
            justify-content: space-between;
            position: absolute;
            margin: 0 auto;
            margin-top: 200px;
        }
    }

    /* .buttons-container {
        .left-button {
            position: absolute;
            left: -1em;
            top: 200px;
        }
        .right-button {
            top: 200px;
            position: absolute;
            right: -1em;
        }
    } */
`;

export const AboutCarousel = () => {
    const [index, setIndex] = useState(0);

    const onSelect = (i: React.SetStateAction<number>) => {
        setIndex(i);
    };

    const onRightArrowPush = () => {
        console.log(index);
        if (index <= 9) {
            setIndex(index => index + 1);
        } else {
            return;
        }
    };

    const onLeftArrowPush = () => {
        if (index >= 1) {
            setIndex(index => index - 1);
        } else {
            return;
        }
    };
    return (
        <StyledTour>
            <div className="buttons-container">
                <div className="left-button">
                    {index >= 1 ? (
                        <button onClick={() => onLeftArrowPush()}>
                            <StaticImage
                                className="arrow"
                                alt="light grey arrow"
                                placeholder="blurred"
                                quality={100}
                                src="../images/svgs/left-dark-arrow.svg"
                            />
                        </button>
                    ) : (
                        <button onClick={() => onLeftArrowPush()}>
                            <StaticImage
                                alt="light grey arrow"
                                placeholder="blurred"
                                quality={100}
                                src="../images/svgs/left-light-arrow.svg"
                            />
                        </button>
                    )}
                </div>
                <div className="right-button">
                    {index >= 10 ? (
                        <button onClick={() => onRightArrowPush()}>
                            <StaticImage
                                alt="light grey arrow"
                                placeholder="blurred"
                                quality={100}
                                src="../images/svgs/right-arrow-light.svg"
                            />
                        </button>
                    ) : (
                        <button onClick={() => onRightArrowPush()}>
                            <StaticImage
                                className="arrow"
                                alt="light grey arrow"
                                placeholder="blurred"
                                quality={100}
                                src="../images/svgs/right-dark-arrow.svg"
                            />
                        </button>
                    )}
                </div>
            </div>
            <Carousel>
                <div className="selection-display">
                    <div className="image-container">
                        {index === 0 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-1.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 1 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-2.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 2 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-3.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 3 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-4.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 4 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-5.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 5 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-6.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 6 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-7.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 7 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-8.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 8 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-9.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 9 && (
                            <>
                                <StaticImage
                                    imgStyle={{ borderRadius: '20px' }}
                                    placeholder="none"
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-10.jpg"
                                    alt="front of veterinary building"
                                />
                            </>
                        )}

                        {index === 10 && (
                            <>
                                <StaticImage
                                    onClick={() => onSelect(10)}
                                    placeholder="none"
                                    imgStyle={{ borderRadius: '20px' }}
                                    quality={100}
                                    src="../images/virtual-tour/virtual-tour-11.jpg"
                                    alt="medical room"
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="pictures-container">
                    <div className="scroll">
                        <StaticImage
                            onClick={() => onSelect(0)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-1.jpg"
                            alt="front of veterinary building"
                        />
                        <StaticImage
                            onClick={() => onSelect(1)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-2.jpg"
                            alt="inside of building"
                        />
                        <StaticImage
                            onClick={() => onSelect(2)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-3.jpg"
                            alt="around corner from main room"
                        />
                        <StaticImage
                            onClick={() => onSelect(3)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-4.jpg"
                            alt="pink and blue animal patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(4)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-5.jpg"
                            alt="medical room"
                        />
                        <StaticImage
                            onClick={() => onSelect(5)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-6.jpg"
                            alt="patient table"
                        />
                        <StaticImage
                            onClick={() => onSelect(6)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-7.jpg"
                            alt="cages"
                        />
                        <StaticImage
                            onClick={() => onSelect(7)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-8.jpg"
                            alt="medical research room"
                        />
                        <StaticImage
                            onClick={() => onSelect(8)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-9.jpg"
                            alt="medical bed"
                        />
                        <StaticImage
                            onClick={() => onSelect(9)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-10.jpg"
                            alt="medical room"
                        />
                        <StaticImage
                            onClick={() => onSelect(10)}
                            placeholder="none"
                            quality={100}
                            src="../images/virtual-tour/virtual-tour-11.jpg"
                            alt="medical room"
                        />
                    </div>
                </div>
            </Carousel>
        </StyledTour>
    );
};
